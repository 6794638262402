@import '~antd/dist/antd.css';

#root, section.ant-layout {
  min-height: 100vh;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  text-align: center;
  font-size: 20px;
  color: #fff;
}

.site-layout-sub-header-background {
  background: #324553;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.site-layout-background {
  background: #fff;
}

.ant-layout.ant-layout-has-sider {
  height: 100%;
}

.green-button {
  background-color: mediumseagreen;
  border-color: mediumseagreen;
}

.green-button:hover {
  background-color: #3cb3719c;
  border-color: #3cb3719c;
}

.ant-input-number {
  width: 100%;
}

.ant-form-vertical .ant-form-item {
  margin-bottom: 10px;
}

.table-filters {
  align-items: center;
  margin-bottom: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 10px;
}